
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Open from './entities/open';


import CompanyAccounts from './entities/company/company-account';
import Contact from './entities/accounts/contact';
import Faq from './entities/accounts/faq';
import StarlightSmartInvest from './entities/starlightsmartinvest';
import SmartInvestAccount from './entities/smartinvestaccount';
import SignInModal from './modules/login/signin';
import SmartReserve2 from './entities/smartReserve/SmartReserve2';
import SmartReserve3 from './entities/smartReserve/SmartReserve3';

import SmartReserve1 from './entities/smartReserve/SmartReserve1';
import SmartReserveHolder from './entities/smartReserve/SmartReserveHolder';
import About from './entities/about';

import Founders from './entities/Founders';
import AdminProfile from './entities/admin-profile/admin-profile';
import Bellwether from './entities/bellwether/bellwether';
import BigTech from './entities/bigtech/bigtech';
import Euroscape from './entities/euroscape/euroscape';
import Starsnstripes from './entities/starsnstripes/starsnstripes';
import AdminSigninModal from './modules/login/AdminSignIn';
import Profile from './modules/profile/profile';
import Accounts from './entities/accounts/accounts';
import CustomFaq from './entities/accounts/custom-faq';
import ForgotPassword from './modules/login/forgotPassword';
import ViewDocument from './entities/accounts/ViewDocument';
import Customer from './entities/customer/customer';
import ResetPassword from './modules/login/ResetPassword';
import ExternalLinks from './entities/accounts/external-links';
import FormDoc from './entities/accounts/formDoc';
import SosDoc from './entities/accounts/SosDoc';
import { SecondDoc } from './entities/accounts/SecondDoc';
import W8benSecondary from './entities/accounts/w8ben_secondary';
import InteractiveBrokers from './entities/accounts/interactive-brokers';
import CompanySos from './entities/company/company-sos';
import { MpsdDoc } from './entities/company/mpsd-doc';
import ChangePassword from './modules/login/changePassword';
import ClientPortal from './modules/profile/client-portal';
import HomeIndex from './shared/layout/homeindex/HomeIndex';
import DashBoard from './shared/layout/dashboard/DashBoard';
import MyStarLight from './entities/dashboard/mystarlight/MyStarLight';
import Deposit from './entities/dashboard/deposit/Deposit';
import Withdraw from './entities/dashboard/withdraw/Withdraw';
import InvestmentPortal from './entities/dashboard/investmentportal/investmentportal';



const loading = <div>loading ...</div>;
const USER_ROLE = 'ROLE';

const ROLE_USER = sessionStorage.getItem(USER_ROLE);
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

// const { loading: fetchLoading, error, data } = useFetch('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
const AppRoutes = () => {

  return (
    <div className="view-routes" id="topSectionHolder">
      <ErrorBoundaryRoutes>
      <Route path="login" element={<Login />} />
      <Route path="/signin" element={<SignInModal />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route
    path="/change-password"
    element={
        <ChangePassword
            showModal={true}
            loginError={null}
            handleLogin={() => {}}
            handleClose={() => {}}
        />
    }
/>

        <Route path='/' element={<HomeIndex/>}>
        <Route index element={<Profile />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/open" element={<Open />} />
        <Route path="/founders" element={<Founders />} />
        <Route path="/invest" element={<SmartInvestAccount />} />?
        <Route path="/accounts" element={<Accounts />} />
        <Route path="money-market-fund/*" element={<SmartReserve2 />} />
        <Route path="portfolio/*" element={<SmartReserve3 />} />
        <Route path="reserve1/*" element={<SmartReserve1 />} />
        <Route path="reserve2/*" element={<SmartReserve2 />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/StarlightSmartInvest" element={<StarlightSmartInvest />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/W-8BEN" element={<CustomFaq />} />
        <Route path="/W-8BENPrimary/:accountId" element={<FormDoc docConfirmation3={undefined} handleCheckboxChangeThree={undefined} handleUpdateNextStep={undefined} FormData2={undefined} email={undefined} setEmail={undefined} name={undefined} accountHolderType={undefined} w8benPrimary_sign={undefined} setw8benPrimary_sign={undefined} w8benSecondarySign={undefined} setw8benSecondarySign={undefined} setDifferentData={undefined} setMailing_address={undefined} mailing_address={undefined} differentData={undefined} />} />
        <Route path="/sos/:accountId" element={<SosDoc FName={undefined} LName={undefined} invest={undefined} docConfirmation={undefined} setDocConfirmation={undefined} sosPrimary_sign={undefined} sosSecondarySign={undefined} accountIdNum={undefined} accountHolderType={undefined} setsosPrimarySign={undefined} setsosSecondarySign={undefined} />} />
        <Route path="/second-doc/:accountId" element={<SecondDoc accountHolderType={undefined} lastName={undefined} name={undefined} secondaryform={undefined} smart_reserve_primary_sign={undefined} setsmart_reserve_primary_sign={undefined} smart_reserve_SecondarySign={undefined} setsmart_reserve_SecondarySign={undefined} />} />
        <Route path="/W-8BENSecondary/:accountId" element={<W8benSecondary docConfirmation3={undefined} handleCheckboxChangeThree={undefined} handleUpdateNextStep={undefined} FormData2={undefined} email={undefined} setEmail={undefined} name={undefined} setsecondaryform={undefined} secondaryform={undefined} accountHolderType={undefined} w8benPrimary_sign={undefined} setw8benPrimary_sign={undefined} w8benSecondarySign={undefined} setw8benSecondarySign={undefined} setDifferentData={undefined} differentData={undefined} mailing_address={undefined} setMailing_address={undefined} />} />
        <Route path="/Ineractive-Broker/:accountId" element={<InteractiveBrokers interactive={undefined} accountHolderType={undefined} ibkrPrimary_sign={undefined} setibkrPrimarySign={undefined} ibkrSecondarySign={undefined} setibkrSecondarySign={undefined} handleCheckboxibustomerAgreement={undefined} handleCheckboxibclientFinancialAdvisor={undefined} handleCheckboxelt={undefined} handleCheckboxibsdNotification={undefined} handleCheckboxLLA={undefined} accountIdNum={undefined} />} />
        <Route path="/company-sos/:accountId" element={<CompanySos companyDetails={undefined} sosPrimary_sign={undefined} setsosPrimarySign={undefined} initialRequirements={undefined} onBehalfOf={undefined} setOnBehalfOf={undefined} authorisePosition={undefined} setAuthorisePosition={undefined} handleOnBehalfOf={undefined} handleAuthorisePosition={undefined} />} />
        <Route path="/company-portfolio/:accountId" element={<MpsdDoc companyDetails={undefined} handleAuthoriseMPSDPosition={undefined} onBehalfOf={undefined} smartReserveAuthorisePosition={undefined} setSmart_reserve_authorise_position={undefined} accountHolderType={undefined} smart_reserve_primary_sign={undefined} setsmart_reserve_primary_sign={undefined} />} />

        <Route path="/company" element={<CompanyAccounts />} />
        <Route path="logout" element={<Logout />} />
        <Route path="smart-reserve/*" element={<SmartReserveHolder />} />
        {/* <Route path="/client-portal" element={<ClientPortal />} /> */}
        {/* <Route path="/user-profile/:id" element={<Profile />} /> */}
        {/* <Route path="/user-profile" element={<Profile />} /> */}
        <Route path="/admin-profile" element={<AdminProfile />} />
        <Route path="/StarlightSmartInvest/bellwether" element={<Bellwether />} />
        <Route path="/StarlightSmartInvest/big-tech" element={<BigTech />} />
        <Route path="/StarlightSmartInvest/euroscape" element={<Euroscape />} />
        <Route path="/StarlightSmartInvest/stars&strips" element={<Starsnstripes />} />
        <Route path="/admin-signin" element={<AdminSigninModal />} />
        <Route path="/view-doc/:accountId" element={<ViewDocument />} />
        <Route
    path="/reset-password"
    element={
        <ResetPassword
            showModal={true}
            loginError={null}
            handleLogin={() => {}}
            handleClose={() => {}}
        />
    }
/>

        <Route path="/terms-and-disclosures" element={<ExternalLinks />} />

        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />

          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/open"
          element={
            <PrivateRoute hasAnyAuthorities={[ROLE_USER]}>
              <Open />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path='/dashboard' element={<DashBoard />}>
          <Route index element={<MyStarLight />} />
          <Route path='/dashboard/deposit' element={<Deposit />} />
          <Route path='/dashboard/withdraw' element={<Withdraw/>}/>
          <Route path='/dashboard/investmentportal' element={<InvestmentPortal/>}/>

        </Route>
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
function useFetch(p0: string): { loading: any; error: any; data: any } {
  throw new Error('Function not implemented.');
}

