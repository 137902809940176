import React, { useEffect, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { Alert, Button, Col, Input, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getIbkr, transaction } from "app/entities/accounts/accounts.reducer";
import CommonToast from "app/modules/components/CommonToast";
import { ibkrportal } from "app/modules/profile/profile.reducer";

const Withdraw = () => {
  const dispatch = useAppDispatch();

  const [amount, setAmount] = useState("0.00");
  const [currency, setCurrency] = useState("EUR"); 
  const [depositDetails, setDepositDetails] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);
  const [completeDocumentation, setCompleteDocumentation] = useState(true);

  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const formatCurrency = (amount: number): string => {
    return amount.toLocaleString('en-EN', {
      minimumFractionDigits: 0.00,
    });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    const numericValue = parseFloat(value);
  
    if (!isNaN(numericValue) && numericValue >= 0) {
      setAmount(value); 
      setErrorMessage('');
    } else if (value === '') {
      setAmount('0.00'); 
      setErrorMessage('');
    } else {
      setErrorMessage('Value cannot be negative');
    }
  };

  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setDepositDetails(response.payload);
        setIbkrNum(response.payload.ibkr_account_number)

      }
    });
  }, []);

  const handleCurrencyChange = (e: any) => {
    setCurrency(e.target.value); 
  };

  const handleTradeOrder = async() => {
    const payload = {
      account_id:depositDetails?.sw_account_id||null,
      ibkr_account_number:depositDetails?.ibkr_account_number||null,
      amount:amount,
      transaction_type:"DEBIT",
      currency:currency,
      bank:""
    }
    try{
      const response:any =await  dispatch(transaction(payload));
      if (response?.payload?.id){
        setToastType('success');
        setToastVisible(true);
        setToastMessage('Your withdrawal has been processed successfully');
      }
      else{
          setToastType('error');
          setToastVisible(true);
          const errorMsg = response?.payload?.message
          const displayMessage = errorMsg.includes("IBKR BadRequest")
          ? errorMsg.replace("IBKR BadRequest", "").trim()
          : 'Access denied';
          setToastMessage(displayMessage)
      }
    }
    catch (error){
      throw error
    }
  };

  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(ibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;
        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };
  return (
    <div className="col-12 pb-5">
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={false}
      />
      {ibkrNum ?(
    <><div className="pt-3 ">
          <Card className=" dashboard-custom-card">
            <CardBody>
              <div className="dashboard-card-heading">
                Important to know when making withdrawals:
              </div>
              <ol>
                <li className="sub-content-dashboard pt-2">
                  You can only withdraw funds from a cleared cash balance in your <b> Starlight Smart Account</b> following the redemption or sale of investments in your <b>Starlight Smart Account</b> in accordance with the
                  <a className="link-invenstment-portal" target="_blank" href="./../../content/policies/starlight_wealth_terms_of_business.pdf">
                    Terms of Business
                  </a> .
                </li>
                <li className="sub-content-dashboard pt-2">
                  You must have your bank account details set up on your <span className="link-invenstment-portal" onClick={handleIBKRPortalLinkClick}> Investment Portal</span> to receive the funds to be withdrawn. If these details have not been set up you will be unable to transfer funds out of your <b>Starlight Smart Account</b>.
                </li>
                <li className="sub-content-dashboard pt-2">
                  There is no charge for the first withdrawal (in each 30 day period); however, Interactive Brokers will charge withdrawal fees for any subsequent withdrawal (€1 per SEPA transfer) in the same period.
                </li>
              </ol>
            </CardBody>
          </Card>
        </div><div className="pt-4 col-12">
            <div className="dashboard-card-heading">
              Withdraw Funds
            </div>
            <div className="col-md-6 withdrawal-div">
              <div className="row pt-3">
                <div className="dashboard-card-sub-heading align-content-center col-4">
                  Withdrawal Amount:
                </div>
                <div className="sub-content-dashboard col-8">
                  <Input
                    type="text"
                    name="withdrawal-amount"
                    id="withdrawal-amount"
                    className="dashboard-select-style"
                    placeholder="0.00"
                    value={amount !== '0.00' ? formatCurrency(parseFloat(amount)) : ''}
                    onChange={handleAmountChange} />

                  {ErrorMessage && <p className="text-danger">{ErrorMessage}</p>}
                </div>
              </div>
              <div className="row pt-3">
                <div className="dashboard-card-sub-heading align-content-center col-4">Currency:</div>
                <div className="sub-content-dashboard col-8">
                  <Input
                    type="select"
                    className="dashboard-select-style"
                    onChange={(e) => handleCurrencyChange(e)}
                  >
                    <option>EUR</option>
                    <option>USD</option>
                    <option>GBP</option>
                  </Input>
                </div>
              </div>
              <div className="col-12 pt-5">
                <Button className="button-account-custom-submit" disabled={!!ErrorMessage} onClick={handleTradeOrder}>Trade Order</Button>
              </div>

            </div>
          </div></>
            ):(
              <Row className='justify-content-center'>
      <Col md="6  ">
        <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
        Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert>
      </Col>
    </Row>
            )}
      </div>
  )
}

export default Withdraw