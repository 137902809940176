
import React, { useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import './login.scss';
import { postLogin } from '../account/register/register.reducer';
import { useAppDispatch } from 'app/config/store';
import './style.scss';
import './successmodal.scss';
import OtpInput from 'react-otp-input';
import CommonToast from '../components/CommonToast';
 
const AdminSigninModal = () => {
  const dispatch = useAppDispatch();
 
  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
 
  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
    navigate('/');
  };
 
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
 
  const [otp, setOtp] = useState('');
 
  const handleLoginSubmit = async () => {
    const data: any = {
      username: Username,
 
      password: Password,
      userType: 'ROLE_ADMIN',
      totp: otp,
    };
    try {
      const response: any = await dispatch(postLogin(data));
      if (response?.payload?.data?.access_token
      ) {
        setToastMessage("Login Successful");
        setToastType('success');
        setToastVisible(true);
 
        setTimeout(() => {
          navigate('/admin-profile');
 
        }, 3000);
        setTimeout(() => {
          window.location.reload()
 
        }, 3000);
      } else {
 
        setToastMessage(response?.payload?.response?.data?.detail);
        setToastType('error');
        setToastVisible(true);
      }
    } catch (error) {
      let errorMessage = 'An unknown error occurred.';
      if (error?.response && error?.response?.data && error?.response?.data?.detail) {
        errorMessage = error?.response?.data?.detail;
      } else if (error?.detail) {
        errorMessage = error?.detail;
      }
      setToastMessage(errorMessage);
      setToastType('error');
      setToastVisible(true);
 
    }
  };
 
  const handleClose=()=>{
    toggleSignInModal();
   }
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    // navigate('/');
    // window.location.reload();
  };
 
  const {
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });
 
  return (
    <>
      <>
        <CommonToast
          visible={toastVisible}
          onClose={() => setToastVisible(false)}
          title={toastType === 'success' ? 'Success' : 'Error'}
          description={toastMessage}
          type={toastType}
          isAutoClose={true}
        />
      </>
      <Modal isOpen={showSignInModal} toggle={toggleSignInModal} className="mt-4">
 
        <Row
          style={{
            // backgroundImage: "url('../../../content/images/mount.jpg')",
            backgroundImage: "url('../../../content/images/starlight-login.webp')",
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // border: '1px solid white',
          }}
          className="justify-content-center"
        >
     
          <Col md="7">
    
          <Row className="mt-5 align-items-center">
                <Col className="d-flex justify-content-center" xs={12} md={12}>
                {/* <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label> */}
                <img className="img-fluid logo-dashboard" src="../../../../../content/images/new_logo_ash_bg_color.svg" />
                </Col>
                <Col className="d-flex justify-content-between align-items-center" xs={12} md={12}>
                  <label style={{ fontSize: '20px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' ,marginTop:'20px',marginLeft:"18px"}}>Log In</label>
                  <Button className="reg-close" onClick={handleClose} style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    X
                  </Button>
                </Col>
                <div className="login-box d-flex" style={{ fontFamily: 'Montserrat',marginLeft:"18px" }}>
                <span className="head1">{`Don't have an account? `}</span>
                <Link to="/login" className="logincolour ps-2">
                 <span> Sign Up</span>
                </Link>
              </div>
              </Row>
            {/* <ModalHeader toggle={toggleSignInModal}>
              Log In
        
            </ModalHeader> */}
            <ModalBody>
              <Row>
                {' '}
                <Col md="12" className="mt-2">
                  <ValidatedField
                    name="Firstname"
                    label="Username"
                    placeholder="Enter your Username"
                    required
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                    className="validated-field1"
                    data-cy="username"
                    validate={{ required: 'Username cannot be empty!' }}
                    style={{ padding: '3px 12px' }}
                    // register={register}
                    error={errors.username as FieldError}
                  // isTouched={touchedFields.username}
                  />
                </Col>{' '}
              </Row>
              <Row>
                <Col md="12" className="mt-4 tt">
                  <ValidatedField
                    name="Password"
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    className="validated-field1"
                    required
                    data-cy="password"
                    validate={{ required: 'password cannot be empty!' }}
                    error={errors.password as FieldError}
                    style={{ padding: '3px 12px' }}
                  // style={{ fontWeight: 'bold' }}
                  // register={register}
                  // error={errors.username as FieldError}
                  // isTouched={touchedFields.username}
                  />
                </Col>
              </Row>
 
              <Row>
                <Col md="12" className="mt-4 tt">
                  <Label for="totp" className="label-account-class" style={{ color: '#9fcdda' }}>
                    TOTP Verification
                  </Label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={props => <input {...props} />}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button className="signin-btn-style" onClick={() => { handleLoginSubmit() }} type="submit">
                Submit
              </Button>
              {/* <Button color="secondary" onClick={toggleSignInModal} >
                Close
              </Button> */}
            </ModalFooter>
          </Col>
        </Row>
      </Modal>
 
    </>
  );
};
 
export default AdminSigninModal;
 
 