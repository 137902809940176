import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row, Col, FormGroup, Button, Label, Input } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import { getCountriesList, updateEntityProperty } from '../accounts/accounts.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { updateEntity } from './company-accounts-reducer';
interface companyFinancialProps {
  onFormComplete: (completed: boolean) => void;
}

export interface companyFinancialRef {
  syncCompanyFinancialWithServer: () => Promise<any>;
}

interface IValidationErrors {
  [key: string]: string;
}
const CompanyFinancial: React.ForwardRefRenderFunction<companyFinancialRef, companyFinancialProps> = ({ onFormComplete }: companyFinancialProps, ref) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [totalNetWortherrors, setTotalNetWorthErrors] = useState<{ [key: string]: string }>({});
  const [liquidNetWortherrors, setliquidNetWorthErrors] = useState<{ [key: string]: string }>({});
  // const [revenue, setRevenue] = useState('');
  const [totalValueError, setTotalValueError] = useState('');
  const [validationError, setValidationError] = useState();
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  // const [LiquidNetWorthError, setLiquidNetWorthError] = useState('');
  const [showOtherDetails, setShowOtherDetails] = useState(false);

  const handleOtherPercentFocus = () => {
    setShowOtherDetails(true);
  };
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const [inputValues, setInputValues] = useState<{ [key: string]: any }>({ ...mainAccountsEntity, ...mainAccountsEntity.company_details } || {});

  //new modifications
  const [revenue, setRevenue] = useState('');
  const [liquidNetWorth, setLiquidNetWorth] = useState(mainAccountsEntity?.liquidNetWorth || '')
  const [totalIncome, setTotalIncome] = useState('')
  const [countriesRevenue, setCountryRevenue] = useState()
  const [buisnesRevenue, setBuisnesRevenue] = useState()
  const [rawValue, setRawValue] = useState(mainAccountsEntity?.totalNetWorth || '');
  const [OtherProvideDetails, setOtherProvideDetails] = useState('')
  const [investmentOrfundingProceeds, setInvestmentOrfundingProceeds] = useState()
  const [sumValueError, setSumValueError] = useState('');
  const [totalNetWorth, setTotalNetWorth] = useState('')
  const [totalNetIncome, setTotalNetIncome] = useState('')

  useImperativeHandle(ref, () => ({
    syncCompanyFinancialWithServer
  }));

  const syncCompanyFinancialWithServer = (): Promise<any> => {
    const _errors: IValidationErrors = {};

    if (!liquidNetWorth) {
      _errors.liquidNetWorth = "This field is mandatory";
    }

    if (!totalNetIncome) {
      _errors.totalNetIncome = "This field is mandatory";
    }
    if (!totalNetWorth) {
      _errors.totalNetWorth = "This field is mandatory";
    }
    // if (!inputValues.business_revenue ) {

    //   _errors.business_revenue = "This field is mandatory";
    // }
    if (sumValueError !== '') {
      _errors.sumValueError = sumValueError;
    }

    if (inputValues.investmentFromOthers > 0 && !inputValues.other_provide_details) {

      _errors.other_provide_details = "This field is mandatory";
    }
    if (inputValues.revenue_10_other_country === 'Yes' && !inputValues.countries_revenue) {

      _errors.countries_revenue = "This field is mandatory";
    }

    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      // toast.error('Please fill all the mandatory fields', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'custom-toast'
      // });

      setToastMessage((prev) => {
        return 'Please fill all the mandatory fields'
      })
      setToastType('error');
      setToastVisible(true);

      return null;
    }

    setErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, is_company_account: true, last_level: 5 }));

  };
 
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
      const newErrors = { ...totalNetWortherrors };
      const newLiquidNetWorthErrors = { ...liquidNetWortherrors };
      inputValues[name] = value;
    const formattedValue = value.replace(/[^\d.]/g, '');
    if (name === 'totalNetWorth') {
      setTotalNetWorth(formattedValue);
      const totalNetWorthNum = parseFloat(formattedValue);
        dispatch(updateEntityProperty({ totalNetWorth: formattedValue }));
    }
    else if (name === 'liquidNetWorth') {
      setLiquidNetWorth(formattedValue)
      const liquidNetWorthNum = parseFloat(formattedValue);
      const totalNetWorthNum = parseFloat(totalNetWorth);
      
      if (liquidNetWorthNum < 50000) {
        newLiquidNetWorthErrors.liquidNetWorth = 'Liquid Net Worth must be greater than or equal to €50,000.';
      } else {
        delete newLiquidNetWorthErrors.liquidNetWorth;
        dispatch(updateEntityProperty({ liquidNetWorth: formattedValue }));
      }
    }
    if (name === 'totalNetIncome') {
      setTotalNetIncome(formattedValue)
    }
    setliquidNetWorthErrors(newLiquidNetWorthErrors);
    setTotalNetWorthErrors(newErrors);

    if (['revenue_10_other_country', 'countries_revenue', 'business_revenue', 'investment_funding', 'other_provide_details'].includes(name)) {
      const newProp: any = { ...mainAccountsEntity.company_details, [name]: value };
      dispatch(updateEntityProperty({
        ['company_details']: newProp,
      }));
    } else if (['liquidNetWorth', 'totalNetWorth', 'totalNetIncome'].includes(name)) {
      dispatch(updateEntityProperty({
        [name]: Number(formattedValue),
      }));
    } else {
      dispatch(updateEntityProperty({
        [name]: value,
      }));

    }
  };

  const validateInputs = () => {
    const liquidNetWorthNum = parseFloat(liquidNetWorth);

    const isLiquidNetWorthValid = !isNaN(liquidNetWorthNum) && liquidNetWorthNum >= 50000;

    if (isLiquidNetWorthValid ) {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
  };

  const handleChangeRevenue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRevenue(e.target.value);
    const value = e.target.value;
    const newProp: any = { ...mainAccountsEntity.company_details, ['revenue_10_other_country']: value === 'Yes' ? true : false };
   
    if (value === 'No') {
      const _errors: IValidationErrors = { ...errors };
      _errors.countries_revenue = '';
      setErrors(_errors);
    }
    inputValues.revenue_10_other_country = value;
    dispatch(updateEntityProperty({

      ['company_details']: newProp,
    }));
  };


  const validateTotalPercentage = () => {
    const buisnessRevenue = inputValues?.business_revenue;
    const InvestmentOrfundingProceeds = inputValues?.investment_funding;
    const otherPercentage = inputValues?.investmentFromOthers;


    const businessRevenueInt = parseFloat(buisnessRevenue) || 0;
    const investmentFundingInt = parseFloat(InvestmentOrfundingProceeds) || 0;
    const otherPercentageInt = parseFloat(otherPercentage) || 0;

    // const businessRevenueInt = parseFloat(mainAccountsEntity?.company_details?.business_revenue) || 0;
    // const investmentFundingInt = parseFloat(mainAccountsEntity?.company_details?.investment_funding) || 0;
    // const otherProvideDetailsInt = parseFloat(mainAccountsEntity?.company_details?.other_provide_details) || 0;

    let totalPercentage = businessRevenueInt + investmentFundingInt;

    if (otherPercentageInt > 0) {
      totalPercentage = businessRevenueInt + investmentFundingInt + otherPercentageInt;
      if (totalPercentage !== 100) {
        setSumValueError('Total of the values must equal 100% (with other details if entered)');
      } else {
        setSumValueError('');
      }
    }
    else if
      (totalPercentage > 100) {
      setSumValueError('Total percentage exceeds 100%');

    }

    else {
      if (totalPercentage !== 100) {
        setSumValueError('Total of the values must equal 100% (without other details if not entered)');
      } else {
        setSumValueError('');
      }
    }
    return totalPercentage;
  };

  useEffect(() => {
    validateTotalPercentage();
    validateInputs();
  }, [inputValues.business_revenue, inputValues.investment_funding, inputValues.investmentFromOthers, inputValues.liquidNetWorth])


  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountriesList());
  }, [dispatch]);
  useEffect(() => {
    setTotalNetWorth(mainAccountsEntity?.totalNetWorth);
    setTotalNetIncome(mainAccountsEntity?.totalNetIncome);
    setLiquidNetWorth(mainAccountsEntity?.liquidNetWorth);
  }, [dispatch]);

  const formatCurrency = (amount: number | string) => {
    if (amount === '' || amount === undefined || amount === null) {
      return '';
    }

    if (isNaN(Number(amount))) {
      return '';
    }

    const parsedAmount = parseFloat(amount.toString());

    return parsedAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };


  return (
    <div>
        <Col md="12" className='pt-3'>
          <FormGroup>
            <Label className="label-custom-form-input">
              <span>* Total Net Worth</span>
            </Label>

            <Input
              type="tel"
              name="totalNetWorth"
              id="totalNetWorth"
              placeholder="€00,000"
              value={formatCurrency(totalNetWorth)}
              // value={inputValues.formatCurrency(rawValue)}
              defaultValue={mainAccountsEntity?.totalNetWorth}
              inputMode="numeric"
              // onChange={handleInputChangeWorth}
              onChange={handleValueChange}
              className="custom-from-input"
            />
            <div className='sub-label-custom-form-input'>
              What is the company’s Total Net Worth ie total assets less liabilities?
            </div>
            {errors.totalNetWorth && <div className="text-danger">{errors.totalNetWorth}</div>}{' '}
            {/* {validationError && !totalIncome && (
              <div className="text-danger">{validationError}</div>
            )}  */}

          </FormGroup>

        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">
              <span>* Liquid Net Worth</span>
            </Label>

            <Input
              type="tel"
              name="liquidNetWorth"
              id="liquidNetWorth"
              placeholder="€00,000"
              defaultValue={mainAccountsEntity?.liquidNetWorth}
              // value={formatCurrency(FormDataNetworth?.liquidnetWorth)}
              value={formatCurrency(liquidNetWorth)}
              inputMode="numeric"
              // onChange={e => setFormDataNetworth({ ...FormDataNetworth, liquidnetworth: e.target.value })}
              //   value={lastName}
              //   onChange={e => setLastName(e.target.value)}

              onChange={handleValueChange}
              // onChange={handleLiquidNetWorthChange}
              className="custom-from-input"
            />
          <div className='sub-label-custom-form-input'>
            What is the company’s Liquid Net Worth / working capital?
              {/* Liquid net worth cannot be greater than total net worth */}
            </div>
            {liquidNetWortherrors.liquidNetWorth && (
              <div className="error-message" style={{ color: 'red' }}>
                {liquidNetWortherrors.liquidNetWorth}
              </div>
            )}
            {errors.liquidNetWorth && <div className="text-danger">{errors.liquidNetWorth}</div>}{' '}
          </FormGroup>

        </Col>
        <Col md="12">
        <FormGroup>
          <Label className="label-custom-form-input">
            <span>* Annual Net Income</span>
          </Label>
          <Input
            type="tel"
            className="custom-from-input"
            name="totalNetIncome"
            id="totalNetIncome"
            placeholder="€00,000"
            value={formatCurrency(totalNetIncome)}
            defaultValue={mainAccountsEntity?.totalNetIncome}
            inputMode="numeric"
            onChange={handleValueChange}
          />
          <div className='sub-label-custom-form-input'>After tax</div>
          {errors.totalNetIncome && <div className="text-danger">{errors.totalNetIncome}</div>}
          {validationError && !inputValues.totalNetIncome && <div className="text-danger">{validationError}</div>}
        </FormGroup>
      </Col>

<Col md="12">
  <FormGroup>
    <Label className="label-custom-form-input">
      <span>Does your company generate 10% or more of its revenue outside of Ireland?</span>
    </Label>
    <Input
      type="select"
      name="revenue_10_other_country"
      id="revenue_10_other_country"
      value={inputValues.revenue_10_other_country}
      onChange={handleChangeRevenue}
      className="custom-from-input"
    >
      <option value="No">No</option>
      <option value="Yes">Yes</option>
    </Input>
    {errors.revenue_10_other_country && <div className="text-danger">{errors.revenue_10_other_country}</div>}
    {validationError && !inputValues.revenue_10_other_country && <div className="text-danger">{validationError}</div>}
  </FormGroup>
</Col>
        {mainAccountsEntity?.company_details?.revenue_10_other_country && (
          <>
            <Col md="12" className=''>
              <FormGroup>
                <Label className="label-custom-form-input">
                  <span>* </span>
                  <span>Please list all countries outside Ireland where the company generates 10% or more of its revenue  </span>
                </Label>

                <Input
                  type="text"
                  name="countries_revenue"
                  id="countries_revenue"
                  className="custom-from-input"
                  value={inputValues.countries_revenue}
                  defaultValue={mainAccountsEntity?.countries_revenue}
                  //   onChange={e => handleMaritalStatusChange(e)}
                  onChange={handleValueChange}
                ></Input>
                <div className='sub-label-custom-form-input'>in this format Country 1 - % , Country 2 - %</div>
                {revenue === 'Yes' && errors.countries_revenue && (
                  <div className="text-danger">{errors.countries_revenue}</div>
                )}
                {/* {validationError && !FormDataNetworth.countriesRevenue && <div className="text-danger">{validationError}</div>} */}
              </FormGroup>
            </Col>
          </>
        )}


      <Col md='12' className="from-sub-heading-custom pt-4">
        Source of Wealth
      </Col>
      <Col md="12" className="form-text-content">
        Please indicate the sources of funds for the investment, with percentages for each (adding to 100%){' '}
      </Col>
      
            <Col md="12" className='pt-3'>
              <FormGroup>
                <Label className="label-custom-form-input">
                  <span>Business Revenue %</span>
                </Label>
                <Input
                  className="custom-from-input"
                  type="tel"
                  name="business_revenue"
                  id="business_revenue"
                  value={inputValues?.business_revenue}
                  defaultValue={mainAccountsEntity.business_revenue}
                  onChange={e => handleValueChange(e)}
                />
                {errors.business_revenue && <div className="text-danger">{errors.business_revenue}</div>}{' '}

                {validationError && !inputValues?.business_revenue && <div className="text-danger">{validationError}</div>}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="label-custom-form-input">
                  <span>Investment / Funding Proceeds %</span>
                </Label>
                {/* <Input
                  className="custom-from-input"
                  type="tel"
                  name="InvestmentOrfundingProceeds"
                  id="InvestmentOrfundingProceeds"
                  value={investmentOrfundingProceeds !== 0 ? investmentOrfundingProceeds : ''}
                  onChange={e => handleValueChange(e)}
                  pattern="[0-9]*"
                  inputMode="numeric"
                /> */}
                <Input
                  className="custom-from-input"
                  type="tel"
                  name="investment_funding"
                  id="investment_funding"
                  value={inputValues?.investment_funding}
                  defaultValue={mainAccountsEntity.investment_funding}
                  onChange={e => handleValueChange(e)}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />

                {errors.investmentOrfundingProceeds && <div className="text-danger">{errors.investmentOrfundingProceeds}</div>}{' '}

                {validationError && !inputValues?.investment_funding && <div className="text-danger">{validationError}</div>}
              </FormGroup>

            </Col>
            <>
              <Col md="12">
                <FormGroup>
                  <Label className="label-custom-form-input">
                    <span>Other %</span>
                  </Label>
                  <Input
                    className="custom-from-input"
                    type="tel"
                    name="investmentFromOthers"
                    id="investmentFromOthers"
                    value={inputValues?.investmentFromOthers}
                    defaultValue={mainAccountsEntity.investmentFromOthers}
                    onChange={e => handleValueChange(e)}
                    onFocus={handleOtherPercentFocus}
                    onClick={handleOtherPercentFocus}
                  />
                  {errors.investmentFromOthers && <div className="text-danger">{errors.investmentFromOthers}</div>}
                </FormGroup>
              </Col>

              {inputValues.investmentFromOthers && (
                <Col md="12">
                  <FormGroup>
                    <Label className="label-custom-form-input">
                      <span>* Other (provide details)</span>
                    </Label>
                    <Input
                      className="custom-from-input"
                      type="text"
                      name="other_provide_details"
                      id="other_provide_details"
                      value={inputValues?.other_provide_details}

                      onChange={e => handleValueChange(e)}
                    />
                    {errors.other_provide_details && <div className="text-danger">{errors.other_provide_details}</div>}
                  </FormGroup>
                </Col>
              )}
            </>
          {sumValueError && <div className="text-danger">{sumValueError}</div>}
        {/* <div className="pt-5">
          <Row>
            <Label className="label-account-class">Funding Account</Label>
            <Label>
              <span className="label-account-class">
                Please provide us with the details of the bank account you will use to fund the company’s Reserve Account
              </span>
            </Label>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class text-left">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Account Holder Name</span>
                </Label>
                <Input
                  className="custom-from-input"
                  type="text"
                  name="accountHolderName"
                  id="accountHolderName"
                  value={FormDataNetworth.accountHolderName}
                  onChange={handleChangeAccountHolderName}
                />
                {errors.accountHolderName && <div className="text-danger">{errors.accountHolderName}</div>}{' '}
                {validationError && !FormDataNetworth.accountHolderName && <div className="text-danger">{validationError}</div>}
 
 
                <span style={{ fontFamily: '10px', color: '#808080' }}>Must be in the company’s name</span>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class text-left">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Bank Name</span>
                </Label>
                <Input
                  className="custom-from-input"
                  type="select"
                  name="bankName"
                  id="bankName"
                  value={FormDataNetworth.bankName}
                  onChange={handleChangeBankName}
                >
 
                  {bankList && bankList.map((item, index) => (
                    <option key={index} value={item.name}>{item.name}</option>
                  ))}
                </Input>
                {errors.bankName && <div className="text-danger">{errors.bankName}</div>}{' '}
                {validationError && !FormDataNetworth.bankName && <div className="text-danger">{validationError}</div>}
 
 
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class text-left">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>IBAN</span>
                </Label>
                <Input
                  className="custom-from-input"
                  type="text"
                  name="iban"
                  id="iban"
                  value={FormDataNetworth.iban}
                  onChange={handleChangeIban}
                />
                {errors.iban && <div className="text-danger">{errors.iban}</div>}{' '}
                {ibanError && <div className="error-message" style={{ color: 'red' }}>{ibanError}</div>}
 
                {validationError && !FormDataNetworth.iban && <div className="text-danger">{validationError}</div>}
              </FormGroup>
 
            </Col> */}
        {/* <Col md="12">
              <FormGroup>
                <Label className="label-account-class text-left">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Number of directors</span>
                </Label>
 
                <Input
                  type="select"
                  name="dependents"
                  id="dependents"
                  className="custom-from-input"
                  value={FormDataNetworth.numberOfDirectors}
                  onChange={e =>
                    setFormDataNetworth({
                      ...FormDataNetworth,
                      numberOfDirectors: e.target.value,
                    })
                  }
                >
                  <option value={'0'}>0</option>
                  <option value={'1'}>1</option>
                  <option value={'2'}>2</option>
                </Input>
                {validationError && !FormDataNetworth.numberOfDirectors && <div className="text-danger">{validationError}</div>}
              </FormGroup>
            </Col> */}
        {/* </Row>
        </div> */}
    </div>
  );
};

export default forwardRef(CompanyFinancial);