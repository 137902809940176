import React, { useEffect, useState } from 'react'
import { HouseDoor } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./style.scss"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Popover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'app/config/store';
import { getIbkr, getPortfolio } from 'app/entities/accounts/accounts.reducer';
import { ibkrportal } from "app/modules/profile/profile.reducer";



const navbarlinks = [
    {
      label: "My Starlight",
      value: "Home",
      navlink: "/dashboard",
    },
    {
      label: "Deposit",
      value: "Deposit",
      navlink: "/dashboard/deposit",
    },
    {
      label: "Withdraw",
      value: "Withdraw",
      navlink: "/dashboard/withdraw",
    },
    {
      label: "Investment Portal",
      value: "InvestmentPortal",
      navlink: "",
    },
    
    {
      label: "Documents",
      value: "Documents",
    
    },
    {
      label: "Security",
      value: "Security",
     
    },
    {
      label: "Support",
      value: "Support",
    
    },
    
  
  ];

const DashboardHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const Toggle = () => setIsOpen(!isOpen);
    const [selecetedNav, setSelecetdNav] = useState("Home");
    const [ibkrData, setIbkrData] = useState(null);
    const [ibkrNum, setIbkrNum] = useState(null);
    const [portfolioData, setPortfolioData] = useState(null);



    const dispatch = useAppDispatch();

    const handleIBKRPortalLinkClick = async () => {
      try {
        const resultAction = await dispatch(ibkrportal());
    
        if (resultAction && resultAction.payload) {
          const portalUrl = resultAction.payload;
          
          window.open(portalUrl, '_blank');
        } else {
          console.error('IBKR Portal URL not found');
        }
      } catch (error) {
        console.error('Error fetching IBKR Portal URL:', error);
      }
    };

    const handleSetActiveNavigation = (nav: string) => {
      setSelecetdNav(nav);
      if (nav === 'InvestmentPortal' && ibkrNum){
        handleIBKRPortalLinkClick();
      };
      };
      useEffect(() => {
        dispatch(getIbkr()).then((response: any) => {
          if (response?.payload) {
            setIbkrData(response.payload);
            setIbkrNum(response.payload.ibkr_account_number)

          }
        });
      }, [dispatch]);
      useEffect(() => {
        if(ibkrData){
        dispatch(getPortfolio(ibkrNum)).then((response: any) => {
          if (response?.payload) {
            setPortfolioData(response.payload);
          }
    
        });
        
        }
      }, [ibkrData]);
  
      const copyToClipboard = (text) => {
        if (!text) return; // Don't attempt to copy if there's no text
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
  };
  
  const navigate = useNavigate();
  
  const handleClick = () => {
    window.location.reload();

    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/');
      if (window.location.pathname === '/') {
        window.location.reload();
      }
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/dashboard/deposit") {
      setSelecetdNav("Deposit");
    } else if (pathname === "/dashboard/withdraw") {
      setSelecetdNav("Withdraw");
    } 
  });
  return (
    <header className="header-style header-style-custom ">
      <Navbar className='navhead navbar-container nav-head-one  pt-2 align-items-center'>
          <NavbarBrand tag={Link} to="/https://www.starlightwealth.ie" className='color-nav-brand align-items-center'>
          {/* <img className="img-fluid logo-dashboard" src="../../../../../content/images/black_new_logo.svg" /> */}
          {/* <span className="brand-title d-flex color-nav-brand">
            STARLIGHT WEALTH
          </span> */}
          <img
            src="../../../../../content/images/new_logo_ash_bg_color.svg" 
            className='img-fluid logo-dashboard'
            alt=""
          />
          </NavbarBrand>
          <Nav className=" flex-column  flex-lg-row align-items-center">
          <NavItem >
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle tag={NavLink} className="color-nav-items" caret>
            <FontAwesomeIcon icon={faUser} /> {ibkrData?.ibkr_username}
          </DropdownToggle>
          <DropdownMenu  style={{backgroundColor:"white" , padding:"0px"}}>
            <DropdownItem
              tag={Link}
              to="/logout"
              onClick={handleClick}
              className="dropdown-item-custom text-center"
            >
              Log Out
            </DropdownItem>
              </DropdownMenu>
              </Dropdown>
            {/* <NavLink className='color-nav-items'>
              <FontAwesomeIcon icon={faUser} /> {ibkrData?.ibkr_username}
            </NavLink> */}
          </NavItem>
          <NavItem>
            <NavLink className='color-nav-items'>
              Account No:{ibkrData?.ibkr_account_number}
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrData?.ibkr_account_number)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
  icon={faCopy}
  style={{  color: "#fff" ,cursor: 'pointer', transition: 'transform 0.2s' }}
  onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
  onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className='color-nav-items'>Balance:€{portfolioData?.balance}</NavLink>          
          </NavItem>
          </Nav>
      </Navbar>
          <Navbar
          expand="lg"
          className="navhead nav-head-two navbar-container pt-2"
        >

        

        <NavbarToggler onClick={Toggle} />

        <Collapse
            isOpen={isOpen}
            navbar
            className="collapse-custom text-start pt-2"
          >
            <Nav className=" flex-column  flex-lg-row">
              {navbarlinks.map((item) => (
                <NavItem className='px-2' onClick={() => handleSetActiveNavigation(item.value)}>
                  <NavLink
                    tag={Link}
                    className={`nav-link nav-text relative-div-bg ${
                      selecetedNav === item.value ? "navbaractive" : ""
                    }`}
                    to={item.navlink}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ))}
                      
                      {/* <NavItem className='px-2'>
                  <NavLink
                    tag={Link}
                    className={`nav-link nav-text relative-div-bg ${
                      selecetedNav === "logout" ? "navbaractive" : ""
                    }`}
                    to={"/"}
                  >
                    Log Out
                  </NavLink>
                </NavItem> */}
                  </Nav>
              </Collapse>
          </Navbar>
      </header>

  )
}

export default DashboardHeader

