import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch, useAppSelector } from 'app/config/store'
import { depositConfig, getEntity, getIbkr, getIbkrPending, getPortfolio, setAccountLastLevel } from 'app/entities/accounts/accounts.reducer'
import { getPendingTask } from 'app/modules/profile/profile.reducer'
import React, { useEffect, useState } from 'react'
import {  Accordion, Card, CardBody } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { Alert, Button, Col, Row } from 'reactstrap'
 

const MyStarLight = () => {
  const [ibkrData, setIbkrData] = useState(null);
  const [ibkrPending, setIbkrPending] = useState([]);

  const [portfolioData, setPortfolioData] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);
  const [data, setData] = useState([]);
  const [masterType, setMasterType] = useState();
  const [accountNumber, setAccountNumber] = useState(null)
  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const mainAccountsDetails = useAppSelector(state => state.accounts?.entity);
  const [completeDocumentation, setCompleteDocumentation] = useState(true);

  const [holderType, setHolderType] = useState();
  const [PendingTasks, setPendingTasks] = useState([]);

  const [secondaryCustomerId, setSecondaryCustomerId] = useState(null)

  const [depositDetails, setDepositDetails] = useState(null);
  const dispatch = useAppDispatch();
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const navigate = useNavigate();

  
  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setIbkrData(response.payload);
        setIbkrNum(response.payload.ibkr_account_number)
      }
    });
  }, [dispatch]);
  useEffect(() => {
    dispatch(getIbkrPending()).then((response: any) => {
      if (response?.payload) {
        setIbkrPending(response.payload);
        console.log("ibkrpend--->",ibkrPending);
        
        // setIbkrNum(response.payload.ibkr_account_number)
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if(ibkrData){
    dispatch(getPortfolio(ibkrNum)).then((response: any) => {
      if (response?.payload) {
        setPortfolioData(response.payload);
      }

    });
    
  }}, [ibkrData]);

  useEffect(() => {
    dispatch(getAllPendingTask)
    dispatch(depositConfig()).then((response:any) => {
      setDepositDetails(response?.payload);
    });
  }, []);
  
  const onClickPendingTask = (level: number = 1) => {
    dispatch(setAccountLastLevel(level));
    navigate('/accounts');
  };
  const getAllPendingTask=() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setData(response?.payload?.data?.signature_pending)
        const pendingAccountsList = response?.payload?.data?.pending_accounts;
        const signaturePendingList = response?.payload?.data?.signature_pending
        const secondaryId = signaturePendingList?.[0]?.secondary_customer_id;
        setSecondaryCustomerId(secondaryId)
        setMasterType(signaturePendingList?.[0]?.accountMasterType)

        if (signaturePendingList) {
          setAccountNumber(signaturePendingList?.[0]?.accountId)
        }

        if (pendingAccountsList && pendingAccountsList.length > 0 || signaturePendingList && signaturePendingList.length > 0) {
          let firstAccount: any = pendingAccountsList.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );
          const signaturePendingAccount: any = response.payload.data.signature_pending.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );

          let level = '';
          let firstAccountId = '';
          if (pendingAccountsList.length > 0) {
            level = pendingAccountsList[0]?.last_level;
            firstAccountId = pendingAccountsList[0]?.accountId;
          } else {
            if (signaturePendingAccount.length > 0) {
              level = signaturePendingAccount[0]?.last_level;
              firstAccountId = signaturePendingAccount[0]?.accountId;
              firstAccount = signaturePendingAccount;
            }
          }
          dispatch(setAccountLastLevel(level));          
          setPendingTasks(pendingAccountsList);

          const customerId = accountsEntity.customerId;
          const filteredHolderData = pendingAccountsList
            .filter((type: any) => {
              return type.customerId === customerId;
            })
            .map((type: any) => type.accountHolderType);

          setHolderType(filteredHolderData);

          if (firstAccountId !== '') {
            dispatch(getEntity(firstAccountId));
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  const isTaskPending = (taskId: number) => {
    const pendingTasks = [2, 3, 4, 5, 6, 7, 8, 9, 99, 100, 101, 102, 103, 104];
    return pendingTasks.includes(taskId);
  };

 
  const copyToClipboard = (text) => {
    if (!text) return; // Don't attempt to copy if there's no text
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    
  <div className="col-12 pb-5">
      
{!secondaryCustomerId && mainAccountsDetails.accountHolderType?.toLowerCase() !== 'organization' && (
  <>
    { last_level > 1 ? (
      <div className="pt-5 accordion-div-dashboard">
        <Card className="dashboard-custom-card pt-3">
          <CardBody>
            <div className="dashboard-card-heading">My Pending Items & Tasks</div>
            <Accordion alwaysOpen>
              {last_level < 3 && isTaskPending(2) && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="dashboard-card-sub-heading">Primary Applicant Details</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div className="col-3">Primary Applicant Details</div>
                      <div className="col-2">
                        <Button className="button-pending-dashboard">
                          Pending
                        </Button>
                      </div>
                      <div>
                        <a onClick={() => onClickPendingTask(2)} className="text-primary">
                          Primary Applicant Details
                        </a>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {mainAccountsDetails?.accountHolderType !== 'Individual' && last_level < 4 && isTaskPending(3) && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="dashboard-card-sub-heading">Secondary Applicant Details</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div className="col-3">Secondary Applicant Details</div>
                      <div className="col-2">
                        <Button className="button-pending-dashboard">
                          Pending
                        </Button>
                      </div>
                      <div>
                        <a onClick={() => onClickPendingTask(3)} className="text-primary">
                        Secondary Applicant Details                        </a>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {last_level < 5 && isTaskPending(4) && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="dashboard-card-sub-heading">Anti-Money Laundering Requirements</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div className="col-3">Anti-Money Laundering Requirements</div>
                      <div className="col-2">
                        <Button className="button-pending-dashboard">
                          Pending
                        </Button>
                      </div>
                      <div>
                        <a onClick={() => onClickPendingTask(4)} className="text-primary">
                        Anti-Money Laundering Requirements                        </a>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {last_level< 6 && isTaskPending(5)&&(
                <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Your Net Worth and Annual Income</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Your Net Worth and Annual Income</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(5)} className="text-primary">
                      Your Net Worth and Annual Income                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 7 && isTaskPending(6)&&(
                <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Politically Exposed Person (PEP)</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Politically Exposed Person (PEP)</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(6)} className="text-primary">
                      Politically Exposed Person (PEP)                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 8 && isTaskPending(7)&&(
                <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Regulatory Information</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Regulatory Information</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(7)} className="text-primary">
                      Regulatory Information                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 9 && isTaskPending(8)&&(
                <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">My Starlight Smart Reserve Application Summary</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">My Starlight Smart Reserve Application Summary</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(8)} className="text-primary">
                        Summary
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 101 && isTaskPending(100)&&(
                <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Statement of Suitability</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Statement of Suitability</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(100)} className="text-primary">
                      Statement of Suitability
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
               {last_level< 102 && isTaskPending(101)&&(
                <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Starlight Smart Reserve Model Portfolio Description</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Starlight Smart Reserve Model Portfolio Description</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(101)} className="text-primary">
                        Portfolio Description
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
               {last_level< 103 && isTaskPending(102)&&(
                <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">W-8BEN Primary Form</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">W-8BEN Primary Form</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(102)} className="text-primary">
                      W-8BEN
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 105 && isTaskPending(104)&&(
                <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">Interactive Brokers Ireland Standard Terms And Disclosures</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Interactive Brokers Ireland Standard Terms And Disclosures</div>
                    <div className="col-2">
                      <Button className="button-pending-dashboard">
                        Pending
                      </Button>
                    </div>
                    <div>
                      <a onClick={() => onClickPendingTask(104)} className="text-primary">
                      Interactive Brokers Ireland Standard Terms And Disclosures
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
            </Accordion>
          </CardBody>
        </Card>
      </div>
    ) : (
      <>
      {ibkrNum?(
        <>
      <div className='heading-dashboard'>  My Starlight: {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.balance || 0)}
      </div>

      <div className="pt-3">
          <Card className=' dashboard-custom-card'>
              <CardBody>
            <div className='dashboard-card-heading'>
              My Starlight Smart Account:&nbsp;
              <span className='span-account-number px-2 '>{ibkrNum}</span>
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrNum)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
            icon={faCopy}
            style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
            onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
            onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
            </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Investments:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.investment || 0)}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Cash:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.cash || 0)}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Gains  & Interest:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.gain_and_interest || 0)}</div>
                  </div>
              </CardBody>
          </Card>
      </div>
      <div className="pt-3">
      <Card className='dashboard-custom-card'>
      <CardBody>
      <div className='dashboard-card-heading'>
      My Starlight Portfolio
      </div>
      <div className="pt-2">
      <table className='dashboard-table-custom'>
        <thead>
          <tr>
            <th>Investment</th>
            <th>Principal Invested</th>
            <th>Gains & Interest</th>
            <th>Market Value (€)</th>
          </tr>
        </thead>
        <tbody>
        {portfolioData && portfolioData.starlight_portfolio &&
  Object.entries(portfolioData.starlight_portfolio)
    // Sort to place "BASE" first
    .sort(([keyA], [keyB]) => (keyA === "BASE" ? -1 : keyB === "BASE" ? 1 : 0))
    .map(([key, value]: [string, { investment: number; gain_and_interest: number; market_value: number }]) => (
      <tr key={key}>
        {/* Rename "BASE" to "Starlight Smart Reserve" */}
        <td className='sub-content-dashboard'>
          {key === "BASE" ? "Starlight Smart Reserve" : `CASH(${key})`}
        </td>
        <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.investment || 0)}</td>
        
        {/* Only show gain_and_interest for "BASE"; add empty cell for other rows */}
        {key === "BASE" ? (
          <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.gain_and_interest || 0)}</td>
        ) : (
          <td className='sub-content-dashboard'></td> // Empty cell for alignment
        )}
        
        <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.market_value || 0)}</td>
      </tr>
    ))
}


  </tbody>

      </table>
    </div>
   </CardBody>
   </Card>
   {ibkrPending.length>0 &&(
   <div className="pt-5 accordion-div-dashboard">
      <Card className="dashboard-custom-card pt-3">
        <CardBody>
          <div className="dashboard-card-heading">My Pending Items & Tasks</div>
          <Accordion alwaysOpen>
            {ibkrPending?.map((task, index) => (
              <Accordion.Item eventKey={index.toString()} key={task.formNumber}>
                <Accordion.Header>
                  <div className="dashboard-card-sub-heading">
                    {task.formName}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            ))}
          </Accordion>
        </CardBody>
      </Card>
    </div>
    )}

  </div>
  </>
      ):ibkrPending.length>0 ?(
        <>
        <Row className='justify-content-center'>
          <Col md="6  ">
            <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
              Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert>
          </Col>
        </Row>
          <div className="pt-5 accordion-div-dashboard">
              <Card className="dashboard-custom-card pt-3">
                <CardBody>
                  <div className="dashboard-card-heading">My Pending Items & Tasks</div>
                  <Accordion alwaysOpen>
                    {ibkrPending?.map((task, index) => (
                      <Accordion.Item eventKey={index.toString()} key={task.formNumber}>
                        <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                            {task.formName}
                          </div>
                        </Accordion.Header>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </CardBody>
              </Card>
            </div>
            </>
  
      ):(
      <Row className='justify-content-center'>
      <Col md="6  ">
        <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
        Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert>
      </Col>
    </Row>
    )}
     
  </>
    )}
  </>
)}


          {/* <div className="row">
              <div className="col-8">
              <div className='heading-dashboard'>Your Investment Portal</div>
          <div className='content-dashboard pt-3'>
              Click on the <b>Investment Portal</b> button to securely view your portfolio holdings, download investment account activity
              statements and the change your bank details. Your Investment Portal is powered by our investment broker, Interactive Brokers Ireland Limited.
          </div>
              </div>
              <div className="col text-end">
                  <button className='button-dashboard-content'>
                    Investment Portal
                  </button>
              </div>
          </div>
          
          <div className="pt-3">
          <Card className=' dashboard-custom-card'>
              <CardBody>
                  <div className='dashboard-card-heading'>Your Interactive Brokers Account Details:</div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">Account Number:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_number}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">User Name:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_username}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">Account Status:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_status}</div>
                  </div>
              </CardBody>
          </Card>
          </div>

          <div className="pt-4 ">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                    <div className='dashboard-card-heading'>Logging in to the Investment Portal for the first time:                  </div>
                    <ol>
                        <li className='sub-content-dashboard pt-2'>You will need your Interactive Brokers User Name (above) and your password (sent to you by email) to log in.</li>
                        <li className='sub-content-dashboard pt-2'>You will be prompted to change your password, to one which only you will know.</li>
                        <li className='sub-content-dashboard pt-2'>You will be prompted to verify your email address and mobile number, to set up secure 2 Factor Authentication (2FA) on your account.</li>
                    </ol>
                </CardBody>
            </Card>
          </div>
          
          <div className="pt-4 ">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                      <div className='dashboard-card-heading'>Funding your Starlight Wealth Account                  </div>
                      <div className='sub-content-dashboard pt-2'>To fund your Starlight Account, please enter the information below in your online bank app or in your branch.</div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Account Title:</div>
                        <div className="col sub-content-dashboard">  {depositDetails?.["Account Title"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">IBAN:</div>
                        <div className="col sub-content-dashboard">  {depositDetails?.["IBAN"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">BIC:</div>
                        <div className="col sub-content-dashboard">{depositDetails?.["BIC"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Beneficiary Bank:</div>
                        <div className="col sub-content-dashboard"> {depositDetails?.["Beneficiary Bank"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Payment Reference:</div>
                        <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_number}</div>
                      </div>

                </CardBody>
            </Card>
          </div>
          
          <div className="pt-4">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                    <div className='dashboard-card-heading'>Important</div>
                    <ol>
                        <li className='sub-content-dashboard pt-2'>You must include your Interactive Brokers account number as the payment reference.</li>
                        <li className='sub-content-dashboard pt-2'>For EUR fund transfer only, please contact us if you wish to transfer funds in another currency.</li>
                        <li className='sub-content-dashboard pt-2'>Please save the payee and payment details in your online bank app as a regular payee.</li>
                    </ol>
                </CardBody>
            </Card>
          </div>
          
               */}
    </div>

  )
}

export default MyStarLight