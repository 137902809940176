import { useAppDispatch } from "app/config/store";
import { getIbkr } from "app/entities/accounts/accounts.reducer";
import { ibkrportal } from "app/modules/profile/profile.reducer";
import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";



const InvestmentPortal = () => {

    const [ibkrData, setIbkrData] = useState(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getIbkr()).then((response: any) => {
          if (response?.payload) {
            setIbkrData(response?.payload);
          }
        });
      }, [dispatch]);

      const handleIBKRPortalLinkClick = async () => {
        try {
          const resultAction = await dispatch(ibkrportal());
    
          if (resultAction && resultAction.payload) {
            const portalUrl = resultAction.payload;
            window.open(portalUrl, '_blank');
          } else {
            console.error('IBKR Portal URL not found');
          }
        } catch (error) {
          console.error('Error fetching IBKR Portal URL:', error);
        }
      };

return (
    <div className="col-12 pb-5">

    {(ibkrData) && (ibkrData?.ibkr_account_number) && (
        <>
        <div className="d-flex align-items-center justify-content-between py-1 pending-task new-profile-color" >
          <div style={{fontSize:"20px"}}>Your Investment Portal</div>
          <button className='button-investment' onClick={handleIBKRPortalLinkClick} >Investment Portal</button>
        </div>
      
        <div className="user-profile-text1 ibkr-new-font">
          <Row className=' new-profile-color'>
            <div className="text-black ibkr-new-font">Click on the <span style={{color:"black",fontWeight:"bold"}}>Investment Portal</span> button to securely view your portfolio holdings, download investment account activity statements and to change your bank details. Your Investment Portal is powered by our investment broker, <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers</span> Ireland Limited.
            </div>
            <Row>
            <div className="d-flex align-items-center justify-content-between py-2 pending-task-broker" style={{marginTop:"10px"}} >
            <div>Your <span style={{color:"black",fontWeight:"bold"}} >Interactive Brokers</span> Account Details:</div>
            </div>
              <Row>
                <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                  Account Number :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '1.5', fontWeight: '300' }} className="text-black pb-2">
                    {ibkrData?.ibkr_account_number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                  User Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-black pb-2" style={{ lineHeight: '1.5', fontWeight: '300' }}>
                    {ibkrData?.ibkr_username}
                  </span>
                </Col>
                &nbsp;
              </Row>
              <Row>
                <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                  Account Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-black pb-2" style={{ lineHeight: '1.5', fontWeight: '300' }}>
                    {ibkrData?.ibkr_account_status}
                  </span>
                </Col>
                &nbsp;
              </Row>
              <Row>
                <div className="text-black"><span className="d-flex align-items-center justify-content-between py-1 pending-task new-profile-color" style={{marginTop:"20px"}}>Logging in to the Investment Portal for the first time:</span></div>
                 <div style={{marginBottom:"20px"}}>1. You will need your Interactive Brokers User Name (above) and your password (sent to you by email) to log in  <br />
                  2. You will be prompted to change your password, to one which only you will know  <br />
                  3. You will be prompted to verify your email address and mobile number, to set up secure 2 Factor Authentication (2FA) on your account  <br />
                </div> <br />
                <div className='' style={{ fontSize: "17px", color: 'black', fontWeight: 'bold' }}>Funding your Starlight Wealth Account </div>
                <div className="text-black" >To fund your Starlight Account, please enter the information below in your online bank app or in your branch.  </div><br />
                <Row>
                  <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                    Account Title	:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                      Interactive Brokers Ireland
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                    IBAN :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                      DE72501108006231412815
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                    BIC	:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                      CHASDEFXXXX
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                    Beneficiary Bank :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                    JP Morgan SE, TaunusTurm, Taunustor 1, Frankfurt 60310, Germany 
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" style={{lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                    Payment Reference :&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: 'bold' }} className="text-black pb-2">
                      {ibkrData?.ibkr_account_number}
                    </span>
                  </Col>
                </Row>
                <br />
                <div>
                  <div className='' style={{ fontSize: "17px", color: 'black', fontWeight: 'bold',marginTop:"16px" }}>Important: </div>
                  <div>
                    <span className='' style={{ color: 'black', fontWeight: 'bold' }}> 1. You must include your Interactive Brokers account number as the payment reference</span><br />
                    2. For EUR fund transfer only.
                    Please contact us if you wish to transfer funds in another currency <br />
                    3. Please save the payee and payment details in your online bank app as a regular payee <br /><br />
                  </div>
                  {/* <span className="text-black" style={{fontSize:"10px"}}>Please note that Starlight does not accept or hold any client monies. Funds in your Starlight Smart Account are ‘client assets’ and are fully segregated from the assets of IBKR in accordance with Central Bank of Ireland regulatory rules. Please refer to the Interactive Brokers Ireland Limited ‘Client Asset Key Information Document’ in your application form for more information and important disclosures.</span> */}
                </div>
              </Row>
            </Row>
          </Row>
        </div>
      </>
      
      )}
      </div>
)
}
export default InvestmentPortal